import React from 'react';
import { observer } from 'mobx-react';

import { MaskedField } from '~/util/formz/Components/fields/MaskedField';

export const PhoneLookup = observer((props) => {
	const {
		store: {
			form: {
				reactProps,
				fields: {
					phone: phoneField
				}
			}
		}
	} = props;

	const {
		key,
		...formReactProps
	} = reactProps;

	return (
		<form key={key} {...formReactProps}>
			<div className="form-layout">
				<MaskedField field={phoneField} />
			</div>
			<input type="submit" className="ButtonTertiary" value="Search" data-qa="customer-search-button" />
		</form>

	);
});
