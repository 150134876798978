import React from 'react';
import axios, { type AxiosPromise } from 'axios';
import {
	observable, computed, action, makeObservable,
} from 'mobx';
import { fromPromise, type IPromiseBasedObservable } from 'mobx-utils';

import { type GlobalDynamicStore } from '~/global/global-dynamic/Stores/GlobalDynamic.store';
import { EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { ItemsAddedModal } from '~/engage/shop-search/search-results/Components/ItemsAddedModal';
import { cartChangedAction } from '~/cart/Actions/cartChanged.action';
import { SearchResultsProductEventsTrackingStore } from '~/engage/shop-search/search-results/Stores/SearchResults.tracking.store';

type ProductSearchResult = {
	articleNumber: string
	maxQuantity: number
	message: string
	price: string
	quantity: number
	salesText: string
	addToCartStatus: IPromiseBasedObservable<any> | null
};
type SearchResultsDto = {
	exceededMaxSearchResults: boolean
	productSearchResults: ProductSearchResult[]
	_links: {
		quickAddToCart: {
			href: string
		}
	}
};

class SearchResultsStore {
	results: IPromiseBasedObservable<any> | null = null;

	searchParameters: Record<string, any> = {};

	toolbarStore?: EngageToolbarStore;

	magicModal?: any;

	globalDynamicStore?: GlobalDynamicStore;

	constructor() {
		makeObservable(this, {
			results: observable,
			searchParameters: observable,
			toolbarStore: observable,
			magicModal: observable,
			globalDynamicStore: observable,
			title: computed,
			loadSearchResults: action.bound,
			clearMessages: action.bound,
			addMoreHandler: action.bound,
			openItemsAddedModal: action.bound,
			addToCartHandler: action.bound,
			tryAgainHandler: action.bound
		});
	}

	get title() {
		const { customerType, ...searchParams } = this.searchParameters;
		const filteredSearchParams = Object.values(searchParams).filter(Boolean);

		return `Results for “${filteredSearchParams.join(', ')}”`;
	}

	loadSearchResults() {
		const promise: AxiosPromise<SearchResultsDto> = axios
			.get('/api/web/products/search', { params: this.searchParameters })
			.then(response => response.data);

		this.results = fromPromise(promise);
	}

	clearMessages() {
		if (!this.results) {
			return;
		}
		this.results.value.productSearchResults?.forEach?.((result: ProductSearchResult) => {
			result.message = '';
		});
	}

	addMoreHandler() {
		this.magicModal.closeModal();
		if (!this.toolbarStore) {
			return;
		}
		this.toolbarStore.toggleMenu('SHOP', { withMenuStateChange: true });
	}

	openItemsAddedModal() {
		this.magicModal.alterModal({
			title: 'Item(s) Added to Cart',
			content: {
				children: <ItemsAddedModal store={this} />,
			},
		});
	}

	addToCartHandler(result: ProductSearchResult) {
		const data = {
			articleNumber: result.articleNumber,
			quantity: result.quantity
		};

		if (!this.globalDynamicStore) {
			throw new Error('No globalDynamicStore found.');
		}
		const trackingStore = new SearchResultsProductEventsTrackingStore(this.globalDynamicStore.model);

		this.clearMessages();
		if (!this.results) {
			throw new Error('No results found.');
		}
		const promise: AxiosPromise<any> = axios.post(this.results.value._links.quickAddToCart.href, data);
		result.addToCartStatus = fromPromise(promise);
		promise.then((response) => {
			this.globalDynamicStore!.fetchData();
			if (cartChangedAction) {
				cartChangedAction.dispatch();
			}
			this.openItemsAddedModal();
			trackingStore.trackAddToCart(response.data);
		}, (error: unknown) => {
			if (!axios.isAxiosError(error)) {
				return;
			}
			if (error.response?.data.errors[0].errorKey === 'maxQuantityError') {
				result.message = 'Maximum quantity reached.';
			}
		}).then(() => {
			window.setTimeout(() => {
				result.addToCartStatus = null;
			}, 1000);
		});
	}

	tryAgainHandler() {
		this.magicModal.closeModal();
		if (!this.toolbarStore) {
			return;
		}
		this.toolbarStore.toggleMenu('SHOP', { withMenuStateChange: true });
	}
}

const SearchResultsStoreFactory = {
	create: (
		searchParameters: any,
		magicModal: any,
		toolbarStore: EngageToolbarStore,
		globalDynamicStore: GlobalDynamicStore,
	) => {
		const instance = new SearchResultsStore();

		instance.magicModal = magicModal;
		instance.toolbarStore = toolbarStore;
		instance.globalDynamicStore = globalDynamicStore;
		instance.searchParameters = searchParameters;

		instance.loadSearchResults();
		instance.magicModal.alterModal({
			title: instance.title
		});

		return instance;
	},
};

export { SearchResultsStoreFactory };
