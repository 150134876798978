'use client';

import {
	createContext, useContext, useEffect, useState, type ReactNode,
} from 'react';

import { isOnServer } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { CustomerSideMenuStoreFactory } from '~/engage/toolbar/customer-side-menu/Stores/CustomerSideMenu.store';
import { MyOpportunityTrackingStore } from '~/tracking/my-opportunity-events/Stores/MyOpportunity.tracking.store';
import { EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { GlobalDynamic } from '~/global/global-dynamic/Models/GlobalDynamic.model';
import { useRNBRouter } from '~/hooks/useRNBRouter';

const engageToolbarContext = createContext<EngageToolbarStore | null>(null);
const { Provider } = engageToolbarContext;
// Singleton
let engageToolbar: EngageToolbarStore | null = null;

export const ToolbarStoreProvider = ({ children }: { children: ReactNode }) => {
	const {
		clientSidePageTransition: {
			store: magicOverlay,
		},
		globalStaticModel,
		globalDynamicStore,
		magicModal,
		featureTogglesModel,
		HREF,
	} = useGlobalContext();

	const router = useRNBRouter();
	const [isGlobalDynamicLoaded, setGlobalDynamicLoaded] = useState(globalDynamicStore.hasInitiallyLoaded);

	const waitForGlobalDynamic = async () => {
		await new Promise((resolve) => {
			globalDynamicStore.registerClientSideInitialLoadCallback((model: GlobalDynamic) => {
				console.info('globalDynamicStore.registerClientSideInitialLoadCallback', model);
				resolve(model);
			});
		});

		setGlobalDynamicLoaded(true);
	};

	useEffect(() => {
		if (!isGlobalDynamicLoaded && !engageToolbar && !isOnServer) {
			waitForGlobalDynamic();
		}
	}, []);

	if (engageToolbar && !isOnServer) {
		return <Provider value={engageToolbar}>{children}</Provider>;
	}

	const store = EngageToolbarStore.create({ magicOverlay, router });
	const customersMenu = CustomerSideMenuStoreFactory.create(
		globalStaticModel,
		globalDynamicStore,
		magicModal,
		featureTogglesModel,
		HREF,
		store,
	);

	store.setCustomersMenu(customersMenu);
	store.opportunities.globalDynamic = globalDynamicStore;
	store.opportunities.magicOverlay = magicOverlay;
	store.opportunities.magicModal = magicModal;
	store.opportunities.featureTogglesModel = featureTogglesModel;

	MyOpportunityTrackingStore.router = router;
	MyOpportunityTrackingStore.globalDynamicStore = globalDynamicStore;

	engageToolbar = store;
	return <Provider value={engageToolbar}>{children}</Provider>;
};

export const useEngageToolbar = () => {
	const theContext = useContext(engageToolbarContext);

	if (!theContext) {
		throw new Error('No toolbarStoreContext found.');
	}
	return theContext;
};
