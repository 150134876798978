import {
	action, computed, observable, makeObservable,
} from 'mobx';
import axios from 'axios';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { type EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { SearchResultModel } from '~/engage/customer-search/search-results/Stores/SearchResult.model';
import { redirect } from '~/util/redirect';
import type { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';

export class SearchResultsStore {
	results: IPromiseBasedObservable<any> | undefined;

	searchParameters: { [x: string]: any; customerType?: string; } = {};

	viewState;

	featureTogglesModel;

	globalStaticModel;

	globalDynamicStore;

	toolbarStore?: EngageToolbarStore;

	magicModal;

	HREF;

	constructor(
		viewState: ViewStateStore,
		globalStaticModel: any,
		globalDynamicStore: any,
		magicModal: any,
		featureTogglesModel: any,
		HREF: any,
		toolbarStore?: EngageToolbarStore,
	) {
		makeObservable(this, {
			results: observable,
			searchParameters: observable,
			viewState: observable,
			globalStaticModel: observable.ref,
			globalDynamicStore: observable.ref,
			magicModal: observable.ref,
			title: computed,
			loadSearchResults: action.bound,
			continueWithoutMatchingHandler: action.bound,
			linkCurrentCustomerHandler: action.bound,
			mapResultsDataToModel: action.bound,
			tryAgainHandler: action.bound,
			proxyCustomer: action.bound,
		});

		this.viewState = viewState;
		this.featureTogglesModel = featureTogglesModel;
		this.globalStaticModel = globalStaticModel;
		this.globalDynamicStore = globalDynamicStore;
		this.magicModal = magicModal;
		this.HREF = HREF;
		this.toolbarStore = toolbarStore;
	}

	cartPageLink = '/cart';

	get title() {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { customerType, ...searchParams } = this.searchParameters;
		const filteredSearchParams = Object.values(searchParams).filter(Boolean);

		return `Results for: “${filteredSearchParams?.join(', ')}”`;
	}

	loadSearchResults(searchParameters: { [x: string]: any; customerType?: any; }) {
		const searchUrl = this.globalStaticModel.customerSearchLink;

		const promise = axios.get(searchUrl, {
			params: searchParameters,
		}).then((response) => {
			return this.mapResultsDataToModel(response.data.results);
		});

		this.searchParameters = searchParameters;
		this.results = fromPromise(promise);
	}

	continueWithoutMatchingHandler() {
		this.magicModal.closeModal();
	}

	linkCurrentCustomerHandler(href: string, redirectPath: string) {
		this.viewState.goTo('processing');
		axios.post(href).then(() => {
			if (redirectPath) {
				redirect(redirectPath);
			} else {
				window.location.reload();
			}
		}, (error) => {
			console.error('Could not link current customer.', error);
		});
	}

	mapResultsDataToModel(results: any[]) {
		return results?.map?.(result => new SearchResultModel(result));
	}

	tryAgainHandler() {
		this.magicModal.closeModal();
		if (!this.toolbarStore) {
			return;
		}
		this.toolbarStore.toggleMenu('CUSTOMERS', { withMenuStateChange: true });
	}

	proxyCustomer(href: string) {
		if (!href) {
			return false;
		}
		this.viewState.goTo('processing');
		return axios.post(href).then((response) => {
			// don't try to track in Cypress land
			this.globalDynamicStore.fetchData().then(() => {
				// don't try to track in Cypress land
				window.setTimeout(() => {
					console.info('landing page link', response?.data?._links?.proxyLandingPage?.href);
					const proxyLandingPage = response?.data?._links?.proxyLandingPage?.href || this.cartPageLink;

					if (proxyLandingPage) {
						redirect(proxyLandingPage);
					} else {
						window.location.reload();
					}
				}, 100);
			});
		});
	}
}
