import React, { useEffect, useState } from 'react';
import { Provider } from 'mobx-react';

import { type EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { SearchResultsUiStore } from '~/engage/customer-search/search-results/Stores/SearchResultsUi.store';
import { ViewState } from '~/util/viewState/Components/ViewState';

type Props = {
	partialMatchFound?: boolean
	search?: any
	searchResults: any
	continueWithoutMatchingHandler: () => void
	errorResponse?: any
	magicModal: any
	featureTogglesModel: any
	globalDynamicStore?: any
	globalStaticModel?: any
	HREF?: any
	toolbarStore?: EngageToolbarStore
};

export const SearchResultsModal = (props: Props) => {
	const [
		searchResultsMainController,
		setSearchResultsMainController,
	] = useState<SearchResultsUiStore | undefined>(undefined);

	useEffect(() => {
		const search = props.partialMatchFound ? false : props.search;
		const mainController = new SearchResultsUiStore({
			search,
			searchResults: props.searchResults === undefined ? null : props.searchResults,
			partialMatchFound: props.partialMatchFound === undefined ? false : props.partialMatchFound,
			continueWithoutMatchingHandler: props.continueWithoutMatchingHandler,
			errorResponse: props.errorResponse,
			magicModal: props.magicModal,
			featureTogglesModel: props.featureTogglesModel,
			globalDynamicStore: props.globalDynamicStore,
			globalStaticModel: props.globalStaticModel,
			HREF: props.HREF,
			toolbarStore: props.toolbarStore,
		});

		setSearchResultsMainController(mainController);
		mainController.init();
	}, []);

	if (!searchResultsMainController) {
		return null;
	}
	return (
		<Provider searchResultsMainController={searchResultsMainController}>
			<ViewState viewState={searchResultsMainController!.viewState} />
		</Provider>
	);
};
