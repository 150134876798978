import React from 'react';
import { observer } from 'mobx-react';

import { s7ImagePath, sitePath } from '~/global/global.constants';
import { Button } from '~/components/Buttons/Components/Button';

export const FileUpload = observer(({ decode }: { decode: (src: string) => void }) => {
	const buttons = [
		{
			barcodeSrc: `${s7ImagePath}/barcode-sample-blank`,
			label: 'Blank',
		},
		{
			barcodeSrc: `${s7ImagePath}/barcode-sample-invalid`,
			label: 'Invalid',
		},
		{
			barcodeSrc: `${s7ImagePath}/barcode-sample-long`,
			label: 'Too long',
		},
		{
			barcodeSrc: `${s7ImagePath}/barcode-sample-short`,
			label: 'Too short',
		},
		{
			barcodeSrc: `${s7ImagePath}/barcode-sample-valid`,
			label: 'Valid (19-digit)',
		},
		{
			barcodeSrc: `${s7ImagePath}/barcode-sample-valid-30`,
			label: 'Valid (30-digit)',
		},
	];

	return (
		<div className="GiftCardScanner-fileUploadContainer">
			<div className="GiftCardScanner-fileUploadLabel">
				Pick a test barcode image to scan or upload your own
			</div>
			<div className="GiftCardScanner-fileUploadOptions tw-space-y-4">
				{
					buttons.map(({ barcodeSrc, label }) => {
						return (
							<div key={label}>
								<Button
									type="button"
									onClick={() => {
										decode(sitePath + barcodeSrc);
									}}
								>
									{label}
								</Button>
							</div>
						);
					})
				}
				<input
					className="ButtonExtended ButtonExtended--navy01"
					type="file"
					onChange={(event) => {
						const {
							target: {
								files,
							} = {},
						} = event;

						if (!files) {
							return;
						}
						decode(URL.createObjectURL(files[0]));
					}}
				/>
			</div>
		</div>
	);
});
