import {
	observable, action, computed, makeObservable,
} from 'mobx';
import axios from 'axios';

import { type EngageToolbarStore, type MenuItemOptions } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { CustomerLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/CustomerLookup.store';
import { RecentCustomersStoreFactory } from '~/engage/toolbar/customer-side-menu/recent-customers/Stores/RecentCustomers.store';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';

export class CustomerSideMenuStore {
	customersToolbarResults: any;

	errorMessage: any;

	featureTogglesModel: any;

	globalDynamicStore: any;

	globalStaticModel: any;

	HREF: any;

	hasErrors: any;

	lookupStore: any;

	magicModal: any;

	overlay: any;

	recentCustomersStore: any;

	retryLoadCount: any;

	toolbarStore?: EngageToolbarStore;

	get deliveryAvailabilityLink() {
		return this.customersToolbarResults?._links?.deliveryAvailability?.href;
	}

	setCustomerToolbarResults(results: any) {
		this.customersToolbarResults = results;
	}

	setLookupStore(store: any) {
		this.lookupStore = store;
	}

	setRecentCustomersStore(store: any) {
		this.recentCustomersStore = store;
	}

	async load(options?: MenuItemOptions) {
		const {
			globalStaticModel: {
				customersToolbarLink = '',
				links,
			} = {},
		} = this;

		if (!customersToolbarLink) {
			if (links.size === 0 && this.retryLoadCount < 4) {
				this.retryLoadCount++;
				setTimeout(() => {
					this.load(options);
				}, 500);
			} else {
				console.error('Customers toolbar link missing, side menu will not load.');
			}
			return;
		}
		try {
			const promise = axios.get(customersToolbarLink);

			this.hasErrors = false;
			this.overlay.model.position = 50;
			this.overlay.startLoading(promise);
			const resp = await promise;
			const {
				data = {},
				data: {
					customerSearchTypes,
					recentCustomers: {
						recentCustomers = undefined,
						_links: {
							recentCustomersPages: {
								href: recentMoreCustomersLink = '',
							} = {},
						} = {},
					} = {},
				} = {},
			} = resp;

			this.setCustomerToolbarResults(data);
			this.setLookupStore(CustomerLookupStoreFactory.create(
				customerSearchTypes,
				this.globalStaticModel,
				this.globalDynamicStore,
				this.magicModal,
				this.featureTogglesModel,
				this.HREF,
				this.toolbarStore,
				options?.customers?.customerLookupStoreFactoryOptions,
			));
			this.setRecentCustomersStore(RecentCustomersStoreFactory.create(
				recentCustomers,
				recentMoreCustomersLink,
				this.toolbarStore,
				this.globalDynamicStore,
				this.magicModal,
				this.featureTogglesModel,
				this.HREF,
			));
		} catch (error) {
			this.hasErrors = true;
			console.error(error);
		}
	}

	constructor() {
		makeObservable(this, {
			customersToolbarResults: observable.ref,
			setCustomerToolbarResults: action.bound,
			setLookupStore: action.bound,
			setRecentCustomersStore: action.bound,
			deliveryAvailabilityLink: computed,
			globalDynamicStore: observable,
			globalStaticModel: observable,
			hasErrors: observable.ref,
			load: action.bound,
			lookupStore: observable,
			magicModal: observable,
			recentCustomersStore: observable,
		});
	}
}

export const CustomerSideMenuStoreFactory = {
	create(
		globalStaticModel: any,
		globalDynamicStore: any,
		magicModal: any,
		featureTogglesModel: any,
		HREF: any,
		toolbarStore?: EngageToolbarStore,
	) {
		const instance = new CustomerSideMenuStore();

		instance.featureTogglesModel = featureTogglesModel;
		instance.globalStaticModel = globalStaticModel;
		instance.globalDynamicStore = globalDynamicStore;
		instance.magicModal = magicModal;
		instance.HREF = HREF;
		instance.overlay = MagicOverlayStoreFactory.create({ useOpaqueFrosty: true, position: 50 });
		instance.retryLoadCount = 0;
		instance.toolbarStore = toolbarStore;

		return instance;
	},
};
