import axios, { type AxiosPromise } from 'axios';
import {
	observable, makeObservable, reaction, type IReactionDisposer,
} from 'mobx';

import { type LegacyCreateAccountStore } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccount.store';
import { isOnServer } from '~/global/global.constants';
import { FormReactionsPlugin } from '~/util/formz/plugins/FormReactionsPlugin';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { ERROR_KEY } from '~/util/messaging/promise-error/error.constants';

import styles from '~/engage/toolbar/create-account/Components/legacy-create-account.module.scss';

type SubmitHandler = (form: FormBuilder) => AxiosPromise;

export type CreateAccountFormDefaults = {
	username: string
	zipCode: string
	optIn: boolean
};

const createAccountFormModel = (defaults: Partial<CreateAccountFormDefaults> = {}) => {
	// return {
	// 	username: `test${nanoid(6)}@example.com`,
	// 	zipCode: '55445',
	// 	optIn: defaults.optIn || false,
	// };
	return {
		username: defaults.username || '',
		zipCode: defaults.zipCode || '',
		optIn: defaults.optIn || false,
	};
};

export class LegacyCreateAccountFormStore {
	constructor(
		createAccountStore: LegacyCreateAccountStore,
		submitHandler: SubmitHandler,
		defaults: Partial<CreateAccountFormDefaults>,
	) {
		makeObservable(this, {
			model: observable,
			promiseError: observable.ref,
		});

		this.createAccountStore = createAccountStore;
		this.submitHandler = submitHandler;
		this.defaults = defaults;
	}

	createAccountStore: LegacyCreateAccountStore;

	defaults: Partial<CreateAccountFormDefaults>;

	disposers: IReactionDisposer[] = [];

	promiseError?: PromiseError;

	model = {};

	formSettings = {};

	form: FormBuilder | null = null;

	submitHandler: SubmitHandler;

	create() {
		const self = this;

		this.model = createAccountFormModel(this.defaults);
		this.formSettings = {
			id: 'createAccountForm',
			reactProps: {
				className: `${styles['create-account']} formz`,
			},
			settings: {
				plugins: [
					new ValidateAndAjaxSubmitPlugin({
						ajaxSubmit: {
							submitHandler: (form: FormBuilder) => {
								return this.submitHandler(form);
							},
							promiseHandler: async (promise: AxiosPromise) => {
								try {
									await promise;
									await this.createAccountStore.globalDynamicStore.fetchData();
									const { onCreateAccountSuccess } = this.createAccountStore.config;

									if (typeof onCreateAccountSuccess === 'function') {
										onCreateAccountSuccess();
									}
									const successView = this.createAccountStore.config.onCreateAccountSuccessView;

									if (successView) {
										this.createAccountStore.viewState.goTo(successView);
									}
								} catch (error) {
									if (axios.isAxiosError(error)) {
										//remaps to error message with search
										if (error?.response?.data?.errors[0]?.errorKey === ERROR_KEY.ACCOUNT_EXISTS_ERROR) {
											error.response.data.errors[0].errorKey = ERROR_KEY.USERNAME_UNIQUE_CONSTRAINT_ERROR;
										}
										this.promiseError = new PromiseError(error);
									}
									this.createAccountStore.viewState.goTo('form');
								}
							},
						},
					}),
					new FormReactionsPlugin({
						reaction(form: FormBuilder) {
							if (isOnServer) {
								return;
							}
							const model = form.model as CreateAccountFormDefaults;

							self.disposers.push(
								reaction(() => model.username, () => {
									self.promiseError = undefined;
								}),
							);
						},
					}),
				],
			},
			fields: {
				username: {
					reactProps: {
						className: 'tw-mb-0',
					},
					control: {
						reactProps: {
							type: 'email',
							autoComplete: 'email',
							maxLength: 241,
							size: 30,
						},
					},
					label: {
						reactProps: {
							children: 'Customer Email Address',
						},
					},
					settings: {
						renderTypeOverride: 'EmailFieldRenderer',
						validationConstraints: {
							presence: {
								message: '^Enter Customer Email Address',
							},
							email: {
								message: '^Please enter a valid email address.',
							},
							length: {
								maximum: 241,
							},
							printableAscii: true,
						},
					},
				},
				zipCode: {
					reactProps: {
						className: 'tw-mt-4',
					},
					control: {
						reactProps: {
							'type': 'text',
							'autoComplete': 'postal-code',
							'maxLength': 10,
							'size': 10,
							'noValidate': true,
							'data-qa': 'zip-code-field',
						},
					},
					label: {
						reactProps: {
							children: 'Customer ZIP Code',
						},
					},
					settings: {
						validationConstraints: {
							presence: {
								message: '^Enter Customer ZIP code',
							},
							zipCode: true,
							length: {
								minimum: 5,
								maximum: 10,
							},
							printableAscii: true,
						},
					},
				},
				optIn: {
					control: {
						reactProps: {
							type: 'checkbox',
						},
					},
					label: {
						reactProps: {
							children: 'Yes, opt in to receive emails about store events, product updates and design ideas.',
						},
					},
					settings: {
						labelNotRequired: true,
					},
				},
			},
		};

		this.form = new FormBuilder(this.model, this.formSettings);
	}
}
