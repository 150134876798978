'use client';

/**
 * @deprecated MARKETING_MMS_OPTIN_ENGAGE (entire file)
 */

import { useEffect } from 'react';
import { observer, Provider } from 'mobx-react';

import { type LegacyCreateAccountStore } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccount.store';
import { ViewState } from '~/util/viewState/Components/ViewState';

type Props = {
	createAccountStore?: LegacyCreateAccountStore
};

export const LegacyCreateAccount = observer((props: Props) => {
	useEffect(() => () => {
		if (!props.createAccountStore?.createAccountFormStore) {
			return;
		}
		props.createAccountStore.createAccountFormStore.disposers.forEach((disposer) => {
			if (typeof disposer !== 'function') {
				return;
			}
			disposer();
		});
	}, []);

	return (
		<Provider createAccountStore={props.createAccountStore}>
			{
				props.createAccountStore !== undefined &&
				<ViewState viewState={props.createAccountStore.viewState} />
			}
		</Provider>
	);
});
