import {
	action, computed, observable, toJS, makeObservable
} from 'mobx';
import axios from 'axios';

import { type FormBuilder } from '~/util/formz/builders/FormBuilder';
import { type MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { type GlobalDynamicStore } from '~/global/global-dynamic/Stores/GlobalDynamic.store';
import { type CreateAccountOptions } from '~/engage/toolbar/set-up-online-access/openSetUpOnlineAccessModal';
import { isString } from '~/util/isString';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { LegacyCreateAccountFormStore, type CreateAccountFormDefaults } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccountForm.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { LegacyForm } from '~/engage/toolbar/create-account/Components/LegacyForm';
import { LegacySuccess } from '~/engage/toolbar/create-account/Components/LegacySuccess';
import { LegacyError } from '~/engage/toolbar/create-account/Components/LegacyError';

export class LegacyCreateAccountStore {
	customerName: string | null;

	createAccountFormStore: LegacyCreateAccountFormStore;

	config: Partial<CreateAccountOptions>;

	globalDynamicStore: GlobalDynamicStore;

	magicModal: MagicModalStore;

	viewState: ViewStateStore;

	constructor(
		magicModal: MagicModalStore,
		globalDynamicStore: GlobalDynamicStore,
		options: Partial<CreateAccountOptions> = {},
	) {
		makeObservable(this, {
			createAccountFormStore: observable,
			createAccountFormOutput: computed,
			submitHandler: action.bound,
			cancelHandler: action.bound,
			updateDeliveryZipCode: action.bound,
			fetchSapCustomerFullName: action.bound,
		});

		this.customerName = null;
		this.magicModal = magicModal;
		this.globalDynamicStore = globalDynamicStore;
		this.config = {
			formDefaults: options?.formDefaults,
			modalOptions: options?.modalOptions,
			onCreateAccountSuccess: options?.onCreateAccountSuccess,
			onCreateAccountSuccessView: options?.onCreateAccountSuccessView || 'success',
		};

		this.viewState = new ViewStateStore(this, {
			loading: {
				component: () => {
					return (
						<div style={{ minHeight: '200px' }}>
							<LoadingSpinner isLoading />
						</div>
					);
				},
			},
			form: {
				component: LegacyForm,
				onEnter: (_, store: LegacyCreateAccountStore) => {
					let title = 'Set Up Online Access';

					if (this.customerName) {
						title = `Set Up Online Access for ${this.customerName.replace(/(.{5})/g, '$1\u00AD')}`;
					}
					this.magicModal.alterModal({
						title,
					});
					return {
						createAccountStore: store
					};
				}
			},
			success: {
				component: LegacySuccess,
				onEnter: (_, store: LegacyCreateAccountStore) => {
					this.magicModal.alterModal({
						title: 'Success! Access Granted'
					});
					return {
						cancelHandler: store.cancelHandler
					};
				}
			},
			error: {
				component: LegacyError,
				onEnter: (viewState, store: LegacyCreateAccountStore, parameters) => {
					this.magicModal.alterModal({
						title: 'Unable to Process'
					});
					return {
						tryAgainHandler: () => {
							console.log(viewState, store, parameters);
							store.viewState.goTo('form');
						},
						cancelHandler: store.cancelHandler
					};
				}
			}
		});
		this.createAccountFormStore = new LegacyCreateAccountFormStore(
			this,
			this.submitHandler,
			this.config.formDefaults || {},
		);
		this.createAccountFormStore.create();

		this.globalDynamicStore.registerClientSideInitialLoadCallback(() => {
			this.fetchSapCustomerFullName().then(() => {
				this.viewState.goTo('form');
			});
		});
	}

	get createAccountFormOutput() {
		return this.createAccountFormStore.model as CreateAccountFormDefaults;
	}

	fetchSapCustomerFullName() {
		const model = this.globalDynamicStore.model as Record<string, unknown>;
		const link = model.createAccountLink;

		if (!link || !isString(link)) {
			return Promise.reject('No link found.');
		}
		const promise = axios.get(link).then((response) => {
			this.customerName = response.data.sapCustomerFullName;
		}).catch((error) => {
			console.error('error getting customers sapCustomerFullName:', error);
		});

		return promise;
	}

	submitHandler(form: FormBuilder) {
		const model = this.globalDynamicStore.model as Record<string, unknown>;
		const link = model.createAccountLink;
		const payload = toJS(this.createAccountFormOutput);

		if (!link || !isString(link)) {
			return Promise.reject('No link found.');
		}
		form.plugins.formValidator.validateForm();
		if (form.plugins.formValidator.hasErrors) {
			return Promise.reject('Invalid form.');
		}
		this.viewState.goTo('loading');
		this.updateDeliveryZipCode(payload.zipCode);
		return axios.post(link, payload);
	}

	cancelHandler() {
		this.magicModal.closeModal();
	}

	updateDeliveryZipCode(zipCode: string) {
		this.globalDynamicStore.transZoneStore.setTransZone(zipCode);
	}
}
