import React from 'react';
import { observer } from 'mobx-react';

import type { Customer } from '~/engage/customer-search/search-results/Types/Customer.interface';
import type { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { type MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { type GlobalDynamicStore } from '~/global/global-dynamic/Stores/GlobalDynamic.store';
import { isEmpty } from '~/util/isEmpty';
import { isFunction } from '~/util/isFunction';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { openSetUpOnlineAccessModal } from '~/engage/toolbar/set-up-online-access/openSetUpOnlineAccessModal';
import { SearchResultsError } from '~/engage/customer-search/search-results/Components/SearchResultsError';
import { PartialWarningMessage } from '~/engage/customer-search/search-results/Components/PartialWarningMessage';
import { SapCustomer } from '~/engage/customer-search/search-results/Components/SapCustomer';
import { WebCustomer } from '~/engage/customer-search/search-results/Components/WebCustomer';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';

interface Props {
	continueWithoutMatchingHandler?: () => void
	globalDynamic: GlobalDynamicStore;
	magicModal: MagicModalStore;
	partialMatchFound: boolean
	parameters: any
	searchResultsStore: any
	tryAgainHandler?: () => void
	viewState: ViewStateStore;
}

export const SearchResults = observer((props: Props) => {
	const {
		searchResultsStore,
		searchResultsStore: {
			tryAgainHandler,
			searchParameters: {
				email: searchedForEmail = '',
			} = {},
		} = {},
		partialMatchFound = false,
		parameters: {
			searchResults,
			errorResponse
		} = {},
		globalDynamic,
		magicModal,
	} = props;
	let hasErrors = true;
	let results = null;
	const { createAccountLink } = globalDynamic?.model as Record<string, unknown> || {};
	const hasNonWebAccount = () => {
		const typesWithWebAccount = ['WEB', 'LINKED_SINGLE', 'LINKED_MULTI'];
		return searchResultsStore.results && !isFunction(searchResultsStore.results.then) && searchResultsStore.results.some((customer: Customer) => !typesWithWebAccount.includes(customer.type));
	};
	const renderResults = (customers: Customer[] | null) => {
		if (!customers || customers?.length === 0) {
			return (
				<div className={styles['error-message']} data-qa="error-message">
					<span key="server-message" data-qa="error-text">No results. </span>
					<button key="try-again-button" onClick={props.tryAgainHandler}>Try different search terms</button>
					{
						!isEmpty(createAccountLink) &&
						<span>
							<span key="error-text"> or </span>
							<button key="create-new-account-button" data-qa="create-new-account-button" onClick={(event) => {
								event.preventDefault();
								openSetUpOnlineAccessModal(magicModal, globalDynamic, {
									formDefaults: {
										username: searchedForEmail,
									},
								});
							}}>create a new account</button>
						</span>
					}
					<span key="period">.</span>
				</div>
			);
		}

		return (
			<ol className={`${styles['customer-search-result-list']} u-noListStyle tw-ml-0"`} data-qa="customer-search-result-list">
				{
					partialMatchFound &&
					<PartialWarningMessage />
				}
				{
					customers?.map?.((customer, index) => {
						const Customer = customer.type === 'WEB' ? WebCustomer : SapCustomer;

						return (
							<li key={`search-result-item-${customer.id}-${index}`} className={styles['customer-search-result-item']} data-qa="customer-search-result-item">
								<Customer
									partialMatchFound={partialMatchFound}
									customer={customer}
									searchResultsStore={props.searchResultsStore}
									viewState={props.viewState}
									readOnly={customer.creditBlock || false}
								/>
							</li>
						);
					})
				}
			</ol>
		);
	};
	const renderFooter = () => {
		return (
			<div className={styles['customer-search-footer']}>
				<div className={`tw-heading-4 ${styles['customer-search-footer-control-title']}`}>Don’t see your customer?</div>
				<div className="ButtonGroup">
					{
						!partialMatchFound && !isEmpty(createAccountLink) && hasNonWebAccount() &&
						<button className="ButtonAnchor" onClick={(event) => {
							event.preventDefault();
							openSetUpOnlineAccessModal(magicModal, globalDynamic, {
								formDefaults: {
									username: searchResultsStore.searchParameters.email,
								},
							});
						}}>Set up online access</button>
					}
					{
						partialMatchFound &&
						<button className="ButtonAnchor" onClick={(event) => {
							event.preventDefault();
							if (isFunction(props.continueWithoutMatchingHandler)) {
								props.continueWithoutMatchingHandler?.();
							}
							searchResultsStore.continueWithoutMatchingHandler();
						}}>Continue without matching</button>
					}
					{
						!partialMatchFound &&
						<button className="ButtonAnchor" onClick={searchResultsStore.tryAgainHandler}>Try again</button>
					}
				</div>
			</div>
		);
	};

	if (searchResults && errorResponse) {
		hasErrors = true;
		results = (
			<SearchResultsError
				error={errorResponse}
				searchResultsStore={searchResultsStore}
				tryAgainHandler={tryAgainHandler}
				globalDynamic={globalDynamic}
				magicModal={magicModal}
			/>
		);
	} else if (props.parameters?.searchResults || (searchResultsStore.results && typeof searchResultsStore.results.then !== 'function')) {
		hasErrors = false;
		results = renderResults(searchResultsStore.results);
	} else {
		results = searchResultsStore.results.case({
			pending: () => {
				return (
					<div style={{ minHeight: '200px' }}>
						<LoadingSpinner parentSettings={{ width: '100%' }} isLoading />
					</div>
				);
			},
			rejected: (error: unknown) => {
				return (
					<SearchResultsError
						error={error}
						searchResultsStore={searchResultsStore}
						tryAgainHandler={tryAgainHandler}
						globalDynamic={globalDynamic}
						magicModal={magicModal}
					/>
				);
			},
			fulfilled: (customers: Customer[]) => {
				hasErrors = false;
				return renderResults(customers);
			}
		});
	}

	return (
		<div className={styles['customer-search-results']} data-qa="customer-search-results">
			{results}
			{
				!hasErrors &&
				renderFooter()
			}
		</div>
	);
});
