import React from 'react';
import { observer } from 'mobx-react';

import { isEmpty } from '~/util/isEmpty';
import { openSetUpOnlineAccessModal } from '~/engage/toolbar/set-up-online-access/openSetUpOnlineAccessModal';
import { OrderSearchResultsError } from '~/engage/customer-search/search-results/Components/OrderSearchResultsError';
import { PurchaseOrderSearchResultsError } from '~/engage/customer-search/search-results/Components/PurchaseOrderSearchResultsError';

import styles from '~/engage/customer-search/search-results/customer-linking/Components/customer-search-results.module.scss';
import { QuoteSearchResultsError } from '~/engage/customer-search/search-results/Components/QuoteSearchResultsError';

interface Props {
	tryAgainHandler: any
	error: any
	searchResultsStore: any
	magicModal: any
	globalDynamic: any
}

export const SearchResultsError = observer((props: Props) => {
	const {
		error: {
			response: {
				status = undefined,
				statusText = '',
				data: {
					message = '',
				} = {},
			} = {},
		} = {},
		searchResultsStore,
		magicModal,
		globalDynamic,
		globalDynamic: {
			model: {
				createAccountLink = ''
			} = {},
		} = {},
	} = props;
	let errorMessage;
	let createAccount = null;
	const {
		searchParameters: {
			orderNumber = '',
			quoteNumber = '',
			purchaseOrderNumber = '',
		} = {},
	} = searchResultsStore;

	if (quoteNumber) {
		return (
			<QuoteSearchResultsError
				httpStatusCode={status}
				tryAgainHandler={props.tryAgainHandler}
			/>
		);
	}

	if (orderNumber) {
		return (
			<OrderSearchResultsError
				httpStatusCode={status}
				tryAgainHandler={props.tryAgainHandler}
			/>
		);
	}

	if (purchaseOrderNumber) {
		return (
			<PurchaseOrderSearchResultsError
				httpStatusCode={status}
				tryAgainHandler={props.tryAgainHandler}
			/>
		);
	}

	switch (status) {
		case 403:
			errorMessage = [
				<strong key="server-message" className="error-text">{message} </strong>,
				<button key="try-again-button" onClick={props.tryAgainHandler}>Try again</button>,
				<span key="error-text"> with different or more specific search terms.</span>,
			];
			break;
		case 400:
		case 404:
			if (!isEmpty(createAccountLink)) {
				createAccount = [
					<span key="error-text"> or </span>,
					<button key="create-new-account-button" data-qa="create-new-account-button" onClick={(event) => {
						event.preventDefault();
						openSetUpOnlineAccessModal(magicModal, globalDynamic, {
							formDefaults: {
								username: searchResultsStore.searchParameters.email,
							},
						});
					}}>create a new account</button>
				];
			}
			errorMessage = [
				<span key="server-message" className="error-text">No results. </span>,
				<button key="try-again-button" onClick={props.tryAgainHandler}>Try different search terms</button>,
				createAccount,
				<span key="period">.</span>,
			];
			break;
		case 500:
			errorMessage = (
				<strong className="error-text">
					Request timeout. Please <button className="button-as-anchor" onClick={props.tryAgainHandler}>try again</button> or if the problem persists, open a Help Desk ticket.
				</strong>
			);
			break;
		default:
			errorMessage = <strong className="error-text">{statusText} </strong>;
			break;
	}
	return (
		<div className={styles['error-message']} data-qa="error-message">
			{errorMessage}
		</div>
	);
});
