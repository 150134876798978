import { type Dispatch, type SetStateAction, type RefObject } from 'react';
import axios from 'axios';
import {
	model, Model, prop, idProp, modelFlow, _async, _await, modelAction,
} from 'mobx-keystone';

import { type ActiveMenuStates } from '~/engage/toolbar/Components/EngageToolbar';
import type { CustomerLookupStoreFactoryOptions } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/CustomerLookup.store';
import type { CustomerSideMenuStore } from '~/engage/toolbar/customer-side-menu/Stores/CustomerSideMenu.store';
import { type OpenDeliverySummaryStore, OpenDeliverySummaryStoreFactory } from '~/engage/toolbar/open-delivery-summary/Stores/OpenDeliverySummary.store';
import { modelNamespace } from '~/util/modelNamespace';
import { magicModalCtx } from '~/global/Models/Global.model';
import { isOnServer, _links } from '~/global/global.constants';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { type ViewStateView } from '~/util/viewState/ViewState.interface';
import { CustomerSideMenu } from '~/engage/toolbar/customer-side-menu/Components/CustomerSideMenu';
import { QuickLinks } from '~/engage/toolbar/side-menu/Components/QuickLinks';
import { Shop } from '~/engage/toolbar/side-menu/Components/Shop';
import { noop } from '~/util/noop';
import { OpportunitiesStore } from '~/engage/opportunities/Stores/Opportunities.store.root';
import { IncognitoLocationStore } from '~/engage/toolbar/incognito-location/Stores/IncognitoLocation.store';
import { RNBRouter } from '~/hooks/useRNBRouter';

const menuViews = {
	CUSTOMERS: 'CUSTOMERS',
	QUICK_LINKS: 'QUICK_LINKS',
	SHOP: 'SHOP',
} as const;

type MenuView = typeof menuViews;
export type MenuViewKey = keyof MenuView;
type Config = {
	magicOverlay: any
	router: RNBRouter
};
type ViewStateConfig = {
	[key in MenuViewKey]: ViewStateView
};
export type MenuItemOptions = {
	customers?: {
		customerLookupStoreFactoryOptions?: CustomerLookupStoreFactoryOptions
	},
	withMenuStateChange?: boolean
};
type ViewStateParameters = {
	menuItemOptions?: MenuItemOptions
};

@model(`${modelNamespace.ENGAGE_TOOLBAR}/ToolbarStore`)
export class EngageToolbarStore extends Model({
	id: idProp,
	activeSideMenu: prop<MenuViewKey | null>(null).withSetter(),
	edgeDetectionMarginX: prop(20).withSetter(),
	newGuestButtonDisabled: prop(false).withSetter(),
	opportunities: prop<OpportunitiesStore>(),
	onlineAccessSubmenuOffsetLeft: prop<string | null>(null).withSetter(),
	opportunitySubmenuOffsetLeft: prop<string | null>(null).withSetter(),
	openDeliverySubmenuOffsetLeft: prop<string | null>(null).withSetter(),
}) {
	router: RNBRouter | undefined;

	protected onInit() {
		this.setViewState(new ViewStateStore(this, this.viewStateConfig));
		this.setIncognitoLocation(new IncognitoLocationStore('', {}));
		this.setOpenDeliverySummary(OpenDeliverySummaryStoreFactory.create());
	}

	static create(config: Config) {
		const store = new _EngageToolbarStore({
			opportunities: new OpportunitiesStore({}),
		});

		store.setOverlay(config.magicOverlay);
		store.router = config.router;
		// @ts-ignore
		// global.tb = store;
		return store;
	}

	customersMenu?: CustomerSideMenuStore;

	get hasActiveSideMenu() {
		return Boolean(this.activeSideMenu);
	}

	incognitoLocation?: IncognitoLocationStore;

	get magicModal() {
		return magicModalCtx.get(this);
	}

	magicOverlay: any;

	get openDeliverySubmenuLoaded() {
		return Boolean(this.openDeliverySummary?.model?.hasOpenDeliveryGroups);
	}

	openDeliverySummary?: OpenDeliverySummaryStore;

	setActiveSideMenuState: Dispatch<SetStateAction<ActiveMenuStates>> = noop;

	viewState!: ViewStateStore;

	viewStateConfig: ViewStateConfig = {
		CUSTOMERS: {
			component: CustomerSideMenu,
			onEnter: (_, store: EngageToolbarStore, parameters: ViewStateParameters) => {
				if (!store.customersMenu) {
					throw new Error('No customersMenu found.');
				}
				store.customersMenu.load(parameters?.menuItemOptions);
				return { menuStore: store.customersMenu };
			},
		},
		QUICK_LINKS: {
			component: QuickLinks,
		},
		SHOP: {
			component: Shop,
		},
	};

	@modelAction
	closeMenu() {
		this.setActiveSideMenu(null);
		this.setActiveSideMenuState({
			CUSTOMERS: false,
			QUICK_LINKS: false,
			SHOP: false,
		});
		// This needs to happen after reseting the above state.
		this.setActiveSideMenu(null);
	}

	@modelAction
	edgeDetection(submenuRef: RefObject<HTMLDivElement>, name: string) {
		if (!submenuRef.current) {
			return;
		}
		const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
		// const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
		const { x, width } = submenuRef.current.getBoundingClientRect();
		const total = x + width;
		const diff = Math.round(Math.abs(total - vw));
		const { offsetLeft } = submenuRef.current;
		const result = `${(offsetLeft - diff - this.edgeDetectionMarginX)}px`;
		// console.log('vw:', vw);
		// console.log('x:', x);
		// console.log('width:', width);
		// console.log('total:', total);
		// console.log('diff:', diff);
		// console.log('new x:', x - diff);

		if (total < vw) {
			return;
		}
		console.info('Edge detection overlap ocurred.');
		switch (name) {
			case 'delivery-summary':
				this.setOpenDeliverySubmenuOffsetLeft(result);
				break;
			case 'online-access':
				this.setOnlineAccessSubmenuOffsetLeft(result);
				break;
			default:
				console.warn('No offset was updated.');
		}
	}

	@modelFlow
	logout = _async(function* (this: EngageToolbarStore, logoutLink: string) {
		try {
			const promise = axios.post(logoutLink);

			yield* _await(promise);
			window.location.reload();
		} catch (error: unknown) {
			this.onError(error);
		}
	});

	onError(error: unknown) {
		if (isOnServer || !axios.isAxiosError(error)) {
			console.error(error);
			return;
		}
		const promiseError = new PromiseError(error);

		this.magicModal.openModal({
			title: 'Error',
			maxWidth: '625px',
			content: {
				children: <PromiseErrorMessaging errorMessage={promiseError.errorMessageObj} />,
			},
		});
	}

	onOpenDeliverySummaryMenuOpen(openDeliveryGroupsLink: string) {
		if (!this.openDeliverySummary || isOnServer) {
			return;
		}
		this.openDeliverySummary.model.openDeliveryGroupsLink = openDeliveryGroupsLink;
		if (this.openDeliverySummary.model.data) {
			return;
		}
		this.openDeliverySummary.init();
	}

	onProfileMenuOpen(locationSelectorLink: string, defaultStoreName: string) {
		if (!this.incognitoLocation || this.incognitoLocation.locations.length) {
			return;
		}
		this.incognitoLocation.link = locationSelectorLink;
		this.incognitoLocation.defaults = {
			location: defaultStoreName,
		};
		this.incognitoLocation.initForm();
		this.incognitoLocation.fetchLocations();
	}

	toggleMenu(view?: MenuViewKey, options?: MenuItemOptions) {
		if (this.activeSideMenu === view || !view) {
			this.setActiveSideMenu(null);
			return;
		}
		this.viewState.goTo<ViewStateParameters>(view, { menuItemOptions: options });
		this.setActiveSideMenu(view);
		if (options?.withMenuStateChange) {
			this.setActiveSideMenuState({
				CUSTOMERS: view === 'CUSTOMERS',
				QUICK_LINKS: view === 'QUICK_LINKS',
				SHOP: view === 'SHOP',
			});
		}
	}

	setCustomersMenu(menu: CustomerSideMenuStore) {
		this.customersMenu = menu;
	}

	setIncognitoLocation(incognitoLocation: IncognitoLocationStore) {
		this.incognitoLocation = incognitoLocation;
	}

	setOpenDeliverySummary(openDeliverySummary: OpenDeliverySummaryStore) {
		this.openDeliverySummary = openDeliverySummary;
	}

	setOverlay(magicOverlay: any) {
		this.magicOverlay = magicOverlay;
	}

	setViewState(viewState: ViewStateStore) {
		this.viewState = viewState;
	}

	@modelFlow
	shopWithNewGuest = _async(function* (this: EngageToolbarStore) {
		try {
			const promise = axios.post(_links.shopWithNewGuest.href);

			this.setNewGuestButtonDisabled(true);
			yield* _await(promise);
			if (this.router) {
				this.router.push('/');
			}
		} catch (error: unknown) {
			this.onError(error);
		} finally {
			this.setNewGuestButtonDisabled(false);
		}
	});
}

const _EngageToolbarStore = EngageToolbarStore;
