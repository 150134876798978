'use client';

/**
 * @deprecated MARKETING_MMS_OPTIN_ENGAGE (entire file)
 */

import { observer } from 'mobx-react';

type Props = {
	cancelHandler: () => void
	tryAgainHandler: () => void
};

export const LegacyError = observer((props: Props) => {
	return (
		<div>
			<p>System failed. Please try again or cancel.</p>
			<div className="form-actions ButtonGroup">
				<button
					className="Button tw-uppercase"
					onClick={props.tryAgainHandler}
					type="button"
				>
					Try Again
				</button>
				<button
					className="ButtonAnchor"
					onClick={(event) => {
						event.preventDefault();
						props.cancelHandler();
					}}
					type="button"
				>
					Cancel
				</button>
			</div>
		</div>
	);
});
