import { action } from 'mobx';

import { type CreateAccountFormDefaults } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccountForm.store';
import { type MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { type GlobalDynamicStore } from '~/global/global-dynamic/Stores/GlobalDynamic.store';
import { SetUpOnlineAccess } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess';
import { LegacyCreateAccountStore } from '~/engage/toolbar/create-account/Stores/LegacyCreateAccount.store';
import { LegacyCreateAccount } from '~/engage/toolbar/create-account/Components/LegacyCreateAccount';
import { SetUpOnlineAccessStore } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess.store';

export type CreateAccountOptions = {
	formDefaults: Partial<CreateAccountFormDefaults>
	modalOptions: object
	onCreateAccountSuccess: () => void
	onCreateAccountSuccessView: string
};

export const openSetUpOnlineAccessModal = action((
	magicModal: MagicModalStore,
	globalDynamicStore: GlobalDynamicStore,
	options: Partial<CreateAccountOptions> = {},
) => {
	const { modalOptions } = options;
	const activeFeatureToggles = 'activeFeatureToggles' in globalDynamicStore.model
		? globalDynamicStore.model.activeFeatureToggles
		: [];
	const createAccountStore = new LegacyCreateAccountStore(magicModal, globalDynamicStore, options);

	if (Array.isArray(activeFeatureToggles) && activeFeatureToggles.includes('MARKETING_MMS_OPTIN_ENGAGE')) {
		const store = SetUpOnlineAccessStore.create();

		magicModal.openModal({
			id: 'create-account',
			title: 'Set Up Online Access',
			maxWidth: '535px',
			onCloseFocusElement: '',
			content: {
				children: <SetUpOnlineAccess store={store} />,
			},
			...modalOptions,
		});
		// magicModal.openModal({
		// 	id: 'create-account',
		// 	title: 'Set Up Online Access',
		// 	maxWidth: '535px',
		// 	content: {
		// 		children: <LegacyCreateAccount createAccountStore={createAccountStore} />,
		// 	},
		// 	...modalOptions,
		// });
		return;
	}

	magicModal.openModal({
		id: 'create-account',
		title: 'Set Up Online Access',
		maxWidth: '410px',
		content: {
			children: <LegacyCreateAccount createAccountStore={createAccountStore} />,
		},
		...modalOptions,
	});
});
