import React from 'react';
import { observer } from 'mobx-react';

import { EmailField } from '~/util/formz/Components/fields/EmailField';

export const EmailLookup = observer(({ store = {} }) => {
	const {
		form: {
			reactProps = {},
			fields: {
				email: emailField = {},
			} = {},
		} = {},
	} = store;

	const {
		key,
		...formReactProps
	} = reactProps;

	return (
		<form key={key} {...formReactProps}>
			<div className="form-layout">
				<EmailField field={emailField} />
			</div>
			<input type="submit" className="ButtonTertiary" value="Search" data-qa="customer-search-button" />
		</form>
	);
});
