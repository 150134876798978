'use client';

import React from 'react';
import cn from 'classnames';

import { type MenuItemOptions } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useEngageToolbar } from '~/engage/toolbar/Contexts/EngageToolbar.context';
import { TextButton } from '~/components/Buttons/Components/TextButton';

export type AccountExistsCheckoutErrorMessageProps = {
	values: {
		email: string
	}
	inline?: boolean
};

export const AccountExistsCheckoutErrorMessage = ({ inline, values }: AccountExistsCheckoutErrorMessageProps) => {
	const { email } = values;
	const { magicModal } = useGlobalContext();
	const engageToolbar = useEngageToolbar();

	return (
		<div data-qa="account-exists-checkout-error-message" className={cn({ 'tw-text-red': inline })}>
			An account already exists for this email address. Please{' '}
			<TextButton
				type="button"
				data-qa="search-this-customer-button"
				onClick={() => {
					const options: MenuItemOptions = {
						customers: {
							customerLookupStoreFactoryOptions: {
								emailLookupStoreFactoryOptions: {
									defaultEmailLookupFormModel: {
										email,
									},
								},
							},
						},
						withMenuStateChange: true,
					};

					magicModal.closeModal();
					engageToolbar.toggleMenu('CUSTOMERS', options);
				}}
			>
				search for this customer
			</TextButton>
			.
		</div>
	);
};
