import {
	model, ExtendedModel, prop, idProp, ModelCreationData, modelFlow, _async, _await,
} from 'mobx-keystone';
import { reaction, IReactionDisposer } from 'mobx';

import { type FormSubmitRejectParams } from '~/util/wip/AbstractForm.store';
import { type IFormSettings } from '~/util/formz/Interfaces/FormSettings.interface';
import { setUpOnlineAccessSettings } from '~/engage/toolbar/set-up-online-access/setUpOnlineAccess.settings';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { SetUpOnlineAccessView } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess.view';
import { isOnServer } from '~/global/global.constants';
import { modelNamespace } from '~/util/modelNamespace';
import { AbstractFormStore } from '~/util/wip/AbstractForm.store';
import { isString } from '~/util/isString';
import { rnbFetch } from '~/util/rnbFetch.util';
import { magicModalCtx, globalDynamicCtx } from '~/global/Models/Global.model';

export type FormModel = {
	username: string
	zipCode: string
	optIn: boolean
};

type CreateSettings = ModelCreationData<SetUpOnlineAccessStore>;

type CreateAccountDto = {
	sapCustomerFullName?: string
	_links?: {
		createAccount?: {
			href: string
		}
	}
};

export const isCreateAccountDtoType = (
	subject: unknown,
): subject is CreateAccountDto => {
	if (!subject || typeof subject !== 'object') {
		return false;
	}
	const hasSapCustomerFullName = 'sapCustomerFullName' in subject && isString(subject.sapCustomerFullName);
	const hasCreateAccountHref = (
		'_links' in subject
		&& typeof subject._links === 'object'
		&& subject._links !== null
		&& 'createAccount' in subject._links
		&& subject._links.createAccount !== null
		&& typeof subject._links.createAccount === 'object'
		&& 'href' in subject._links.createAccount
		&& isString(subject._links.createAccount.href)
	);

	return hasSapCustomerFullName || hasCreateAccountHref;
};

@model(`${modelNamespace.ENGAGE_TOOLBAR}/CreateAccountStore`)
export class SetUpOnlineAccessStore extends ExtendedModel(AbstractFormStore<FormModel>, {
	id: idProp,
	username: prop(''),
	zipCode: prop(''),
	optIn: prop(false),
	sapCustomerFullName: prop('').withSetter(),
	showSuccessView: prop(true),
}) {
	static create(settings: CreateSettings = {}) {
		const store = new _SetUpOnlineAccessStore(settings);

		store.viewState.goTo('form');
		return store;
	}

	@modelFlow
	fetchSapCustomerFullName = _async(function* (this: SetUpOnlineAccessStore) {
		const gdModel = globalDynamicCtx.getDefault().model as Record<string, any>;
		const link = gdModel?.createAccountLink;

		try {
			if (!link || !isString(link)) {
				throw new Error('No createAccountLink found.');
			}
			const promise = yield* _await(rnbFetch(link));
			const json = yield* _await(promise.json());

			if (!isCreateAccountDtoType(json)) {
				throw new Error(`Expected json to be of type CreateAccountDto but received ${typeof json}`);
			}
			const { sapCustomerFullName = '' } = json;

			this.setSapCustomerFullName(sapCustomerFullName);
			console.log(json.sapCustomerFullName);
			console.log('promise:', promise);
			console.log('json:', json);
		} catch (error: unknown) {
			console.error('Error getting sapCustomerFullName:', error);
		}
	});

	get formModel(): FormModel {
		return {
			username: this.username,
			zipCode: this.zipCode,
			optIn: this.optIn,
		};
	}

	get formSettings(): IFormSettings {
		return setUpOnlineAccessSettings(this.submitHandler.bind(this), this.promiseHandler.bind(this), this.reactionSettings);
	}

	formSubmitReject = (params: FormSubmitRejectParams) => {
		console.log('params:', params);
		// if (axios.isAxiosError(error)) {
		// 	//remaps to error message with search
		// 	if (error?.response?.data?.errors[0]?.errorKey === ERROR_KEY.ACCOUNT_EXISTS_ERROR) {
		// 		error.response.data.errors[0].errorKey = ERROR_KEY.USERNAME_UNIQUE_CONSTRAINT_ERROR;
		// 	}
		// 	this.promiseError = new PromiseError(error);
		// }
		// this.createAccountStore.viewState.goTo('form');
	};

	formSubmitResolve = () => {
		globalDynamicCtx.getDefault().fetchData();
	};

	get payload(): string {
		throw new Error('Method not implemented.');
	}

	reactionDisposers: IReactionDisposer[] = [];

	reactionSettings = (form: any) => {
		if (isOnServer) {
			return;
		}
		this.reactionDisposers.push(
			reaction(() => form.model.username, () => {
				this.fetchError = undefined;
			}),
		);
	};

	get saveUrl(): RequestInfo | URL {
		throw new Error('Method not implemented.');
	}

	viewState = new ViewStateStore(this, {
		// loading: {
		// 	component: () => {
		// 		return (
		// 			<div style={{ minHeight: '200px' }}>
		// 				<LoadingSpinner isLoading />
		// 			</div>
		// 		);
		// 	},
		// },
		form: {
			component: SetUpOnlineAccessView,
			onEnter: (_, store: SetUpOnlineAccessStore) => {
				let title = 'Set Up Online Access';

				if (this.sapCustomerFullName) {
					title = `Set Up Online Access for ${store.sapCustomerFullName.replace(/(.{5})/g, '$1\u00AD')}`;
				}
				magicModalCtx.getDefault().alterModal({ title });
				return { store };
			},
		},
		success: {
			component: () => null,
			onEnter: (_, store: SetUpOnlineAccessStore) => {
				magicModalCtx.getDefault().alterModal({
					title: 'Success! Access Granted',
				});
				return {
					cancelHandler: store.cancelHandler,
				};
			},
		},
		error: {
			component: () => null,
			onEnter: (viewState, store: SetUpOnlineAccessStore, parameters) => {
				magicModalCtx.getDefault().alterModal({
					title: 'Unable to Process',
				});
				return {
					tryAgainHandler: () => {
						console.log(viewState, store, parameters);
						store.viewState.goTo('form');
					},
					cancelHandler: store.cancelHandler,
				};
			},
		},
	});

	cancelHandler() {
		console.log('cancel');
	}
}

const _SetUpOnlineAccessStore = SetUpOnlineAccessStore;
