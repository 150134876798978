import {
	observable, action, computed, makeObservable
} from 'mobx';
import axios from 'axios';
import React from 'react';

import { type EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { type EmailLookupStoreFactoryOptions } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/EmailLookup.store';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { SearchTypes } from '~/engage/toolbar/customer-side-menu/customer-lookup/Models/SearchTypes.model';
import { customerLookupFormModel, customerLookupFormSettings } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/CustomerLookup.form.settings';
import { EmailLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/EmailLookup.store';
import { noop } from '~/util/noop';
import { EmailLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/EmailLookup.form';
import { PhoneLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/PhoneLookup.store';
import { PhoneLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/PhoneLookup.form';
import { AdvancedResidentialLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/AdvancedResidentialLookup.store';
import { AdvancedResidentialLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/AdvancedResidentialLookup.form';
import { AdvancedBusinessLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/AdvancedBusinessLookup.store';
import { AdvancedBusinessLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/AdvancedBusinessLookup.form';
import { CustomerNumberLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/CustomerNumberLookup.store';
import { CustomerNumberLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/CustomerNumberLookup.form';
import { CartNumberLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/CartNumberLookup.store';
import { CartNumberLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/CartNumberLookup.form';
import { OrderNumberLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/OrderNumberLookup.store';
import { OrderNumberLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/OrderNumberLookup.form';
import { PurchaseOrderLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/PurchaseOrderLookup.store';
import { PurchaseOrderLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/PurchaseOrderLookup.form';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { SearchResultsModal } from '~/engage/customer-search/search-results/Components/SearchResultsModal';
import { QuoteNumberLookup } from '~/engage/toolbar/customer-side-menu/customer-lookup/Components/QuoteNumberLookup.form';
import { QuoteNumberLookupStoreFactory } from '~/engage/toolbar/customer-side-menu/customer-lookup/Stores/QuoteNumberLookup.store';

export type CustomerLookupStoreFactoryOptions = {
	emailLookupStoreFactoryOptions?: EmailLookupStoreFactoryOptions
};

class CustomerLookupStore {
	customerSearchTypes: any;

	isLoading: any;

	hasServerError: any;

	recentPayload: any;

	searchTypes: any;

	viewState: any;

	toolbarStore?: EngageToolbarStore;

	featureTogglesModel: any;

	form: any;

	globalStaticModel: any;

	globalDynamicStore: any;

	magicModal: any;

	HREF: any;

	get saveHref() {
		return this.searchTypes.saveHref(this.form.model.formType);
	}

	changeForm() {
		this.viewState.goTo(this.form.model.formType);
	}

	save(payload: any) {
		this.isLoading = true;
		this.hasServerError = false;
		this.recentPayload = payload;
		axios.post(this.saveHref, payload).then(
			(response) => {
				if (this.toolbarStore) {
					this.toolbarStore.closeMenu();
				}
				this.isLoading = false;
				this.magicModal.openModal({
					maxWidth: 650,
					content: {
						children: (
							<SearchResultsModal
								continueWithoutMatchingHandler={noop}
								search={payload}
								searchResults={response.data.results}
								globalDynamicStore={this.globalDynamicStore}
								featureTogglesModel={this.featureTogglesModel}
								magicModal={this.magicModal}
								globalStaticModel={this.globalStaticModel}
								HREF={this.HREF}
								toolbarStore={this.toolbarStore}
							/>
						),
					},
				});
			},
			this.saveErrorHandler
		);
	}

	saveErrorHandler(error: any) {
		const {
			response: {
				status = undefined,
				data: {
					errors = [],
				} = {},
			} = {},
		} = error;

		this.isLoading = false;
		const sapDownError = errors.find((er: any) => er.errorKey === 'sapConnectionError');

		if (status >= 500 || sapDownError) {
			this.hasServerError = true;
		} else {
			if (this.toolbarStore) {
				this.toolbarStore.closeMenu();
			}
			this.magicModal.openModal({
				maxWidth: 650,
				content: {
					children: (
						<SearchResultsModal
							continueWithoutMatchingHandler={noop}
							search={this.recentPayload}
							searchResults={[]}
							errorResponse={error}
							featureTogglesModel={this.featureTogglesModel}
							globalDynamicStore={this.globalDynamicStore}
							magicModal={this.magicModal}
							toolbarStore={this.toolbarStore}
						/>
					),
				},
			});
		}
	}

	constructor(customerSearchTypes = {}) {
		makeObservable(this, {
			isLoading: observable,
			hasServerError: observable,
			recentPayload: observable,
			searchTypes: observable,
			viewState: observable,
			form: observable,
			globalStaticModel: observable,
			globalDynamicStore: observable,
			magicModal: observable,
			saveHref: computed,
			changeForm: action.bound,
			save: action.bound,
			saveErrorHandler: action.bound
		});

		this.customerSearchTypes = customerSearchTypes;
	}
}

const CustomerLookupStoreFactory = {
	create(
		// eslint-disable-next-line default-param-last
		customerSearchTypes = {},
		globalStaticModel: any,
		globalDynamicStore: any,
		magicModal: any,
		featureTogglesModel: any,
		HREF: any,
		toolbarStore?: EngageToolbarStore,
		options?: CustomerLookupStoreFactoryOptions,
	) {
		const instance = new CustomerLookupStore();

		instance.featureTogglesModel = featureTogglesModel;
		instance.globalStaticModel = globalStaticModel;
		instance.globalDynamicStore = globalDynamicStore;
		instance.magicModal = magicModal;
		instance.HREF = HREF;
		instance.toolbarStore = toolbarStore;
		instance.viewState = new ViewStateStore(instance, {
			EMAIL: {
				// @ts-ignore
				uiKey: 'EMAIL',
				component: EmailLookup,
				onEnter: (_, parentStore) => {
					const store = EmailLookupStoreFactory.create(parentStore, options?.emailLookupStoreFactoryOptions);
					return {
						store,
					};
				},
			},
			PHONE: {
				// @ts-ignore
				uiKey: 'PHONE',
				component: PhoneLookup,
				onEnter: (_, parentStore) => {
					const store = PhoneLookupStoreFactory.create(parentStore);
					return {
						store,
					};
				},
			},
			CUSTOMER_NUMBER: {
				// @ts-ignore
				uiKey: 'CUSTOMER_NUMBER',
				component: CustomerNumberLookup,
				onEnter: (_, parentStore) => {
					const store = CustomerNumberLookupStoreFactory.create(parentStore);
					return {
						store,
					};
				},
			},
			QUOTE_NUMBER: {
				// @ts-ignore
				uiKey: 'QUOTE_NUMBER',
				component: QuoteNumberLookup,
				onEnter: (_, parentStore) => {
					const store = QuoteNumberLookupStoreFactory.create(parentStore);
					return {
						store,
					};
				},
			},
			CART_NUMBER: {
				// @ts-ignore
				uiKey: 'CART_NUMBER',
				component: CartNumberLookup,
				onEnter: (_, parentStore) => {
					const store = CartNumberLookupStoreFactory.create(parentStore);
					return {
						store,
					};
				},
			},
			ORDER_NUMBER: {
				// @ts-ignore
				uiKey: 'ORDER_NUMBER',
				component: OrderNumberLookup,
				onEnter: (_, parentStore) => {
					const store = OrderNumberLookupStoreFactory.create(parentStore);
					return {
						store,
					};
				},
			},
			RESIDENTIAL_ADVANCED: {
				// @ts-ignore
				uiKey: 'RESIDENTIAL_ADVANCED',
				component: AdvancedResidentialLookup,
				onEnter: (_, parentStore) => {
					const store = AdvancedResidentialLookupStoreFactory.create(parentStore);
					return {
						store,
					};
				},
				onExit: (viewState) => {
					// @ts-ignore
					viewState.viewProps.store.destroy();
				},
			},
			BUSINESS_ADVANCED: {
				// @ts-ignore
				uiKey: 'BUSINESS_ADVANCED',
				component: AdvancedBusinessLookup,
				onEnter: (_, parentStore) => {
					const store = AdvancedBusinessLookupStoreFactory.create(parentStore);
					return {
						store,
					};
				},
				onExit: (viewState) => {
					// @ts-ignore
					viewState.viewProps.store.destroy();
				},
			},
			PURCHASE_ORDER: {
				// @ts-ignore
				uiKey: 'PURCHASE_ORDER',
				component: PurchaseOrderLookup,
				onEnter: (_, parentStore) => {
					const store = PurchaseOrderLookupStoreFactory.create(parentStore);
					return {
						store,
					};
				},
			},
		});

		instance.searchTypes = new SearchTypes(customerSearchTypes);
		instance.form = new FormBuilder(
			customerLookupFormModel(instance.searchTypes.defaultType.type),
			customerLookupFormSettings(instance)
		);
		instance.viewState.goTo(instance.searchTypes.defaultType.type);
		instance.hasServerError = false;
		instance.isLoading = false;

		return instance;
	},
};

export { CustomerLookupStoreFactory };
