import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { CartSearchForm } from '~/engage/cart-search/Components/CartSearchForm';
import { CartSearchStoreFactory } from '~/engage/cart-search/Stores/CartSearch.store';
import { useEngageToolbar } from '~/engage/toolbar/Contexts/EngageToolbar.context';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const CartSearch = observer(() => {
	const toolbarStore = useEngageToolbar();
	const { globalDynamicModel, featureTogglesModel } = useGlobalContext();
	const [store] = useState(CartSearchStoreFactory.create(toolbarStore, globalDynamicModel, featureTogglesModel));

	return <CartSearchForm form={store.form} />;
});
