import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';

export const OrderNumberLookup = observer((props) => {
	const {
		store: {
			form: {
				reactProps,
				fields: {
					orderNumber: orderNumberField
				}
			}
		}
	} = props;

	const {
		key,
		...formReactProps
	} = reactProps;

	return (
		<form key={key} {...formReactProps}>
			<div className="form-layout">
				<Field field={orderNumberField} afterLabel={<span className="after-label">Minimum 6 characters</span>} />
			</div>
			<input type="submit" className="ButtonTertiary" value="Search" data-qa="customer-search-button" />
		</form>

	);
});
