import React from 'react';
import axios, { type AxiosError } from 'axios';
import { action, observable, makeObservable } from 'mobx';

import { type EngageToolbarStore } from '~/engage/toolbar/Stores/EngageToolbar.store';
import { redirect } from '~/util/redirect';
import { Modal } from '~/engage/recent-customers/Components/Modal';
import { RecentCustomer } from '~/engage/toolbar/customer-side-menu/recent-customers/Models/RecentCustomer.model';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';

import styles from '~/engage/toolbar/customer-side-menu/Components/recent-customers-modal.module.scss';

class RecentCustomersStore {
	customers: any;

	recentMoreCustomersLink = '';

	toolbarStore: any;

	featureTogglesModel: any;

	globalDynamicStore: any;

	magicModal: any;

	HREF: any;

	isLoading = false;

	constructor() {
		makeObservable(this, {
			customers: observable,
			recentMoreCustomersLink: observable,
			toolbarStore: observable,
			globalDynamicStore: observable,
			magicModal: observable,
			loadAllCustomers: action.bound,
			proxyRecentCustomer: action.bound,
			seeMoreRecentCustomers: action.bound
		});
	}

	loadAllCustomers() {
		if (this.recentMoreCustomersLink) {
			this.isLoading = true;
			const promise = axios.get(this.recentMoreCustomersLink).then(
				(response) => {
					this.isLoading = false;
					this.customers = response.data.recentCustomers.map((cust: any) => {
						return new RecentCustomer(cust);
					});
				},
				() => {
					this.isLoading = false;
					this.customers = [];
				}
			);
			return promise;
		}

		return Promise.reject();
	}

	proxyRecentCustomer(link: string) {
		axios.post(link).then((resp) => {
			this.globalDynamicStore.fetchData().then(() => {
				window.setTimeout(() => {
					redirect(resp?.data?._links?.proxyLandingPage?.href || this.HREF.cart);
				}, 100);
			});
		}, (error: unknown) => {
			if (!axios.isAxiosError(error)) {
				console.error(error);
				return;
			}
			this.onError(error);
		});
	}

	seeMoreRecentCustomers() {
		const modalProps = {
			link: this.recentMoreCustomersLink,
		};

		this.toolbarStore.closeMenu();
		this.magicModal.openModal({
			containerClass: styles['most-recent-customers-modal'],
			id: 'most-recent-customers-modal',
			title: 'My Recent Customers',
			maxWidth: 520,
			content: {
				children: <Modal {...modalProps} />,
			}
		});
	}

	onError(error: AxiosError) {
		const promiseError = new PromiseError(error);
		this.magicModal.closeModal();
		this.magicModal.openModal({
			title: promiseError.errorKey === 'blockedCustomerError' ? 'Alert' : 'Error',
			maxWidth: '500px',
			content: {
				children: <PromiseErrorMessaging errorMessage={promiseError.errorMessageObj} inline />
			}
		});
	}
}

export const RecentCustomersStoreFactory = {
	create(
		recentCustomers: any[],
		recentMoreCustomersLink: string,
		toolbarStore: EngageToolbarStore | undefined,
		globalDynamicStore: any,
		magicModal: any,
		featureTogglesModel: any,
		HREF: any,
	) {
		const store = new RecentCustomersStore();

		store.toolbarStore = toolbarStore;
		store.featureTogglesModel = featureTogglesModel;
		store.globalDynamicStore = globalDynamicStore;
		store.magicModal = magicModal;
		store.customers = recentCustomers.map((customer: any) => new RecentCustomer(customer));
		store.recentMoreCustomersLink = recentMoreCustomersLink;
		store.HREF = HREF;
		return store;
	},
};
