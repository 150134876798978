import { observer } from 'mobx-react';

import { type SetUpOnlineAccessStore } from '~/engage/toolbar/set-up-online-access/SetUpOnlineAccess.store';
import { Field } from '~/util/formz/Components/fields/Field';
import { useSafeSpread } from '~/hooks/useSafeSpread';
import { CheckboxField } from '~/util/formz/Components/fields/CheckboxField';
import { Button } from '~/components/Buttons/Components/Button';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const SetUpOnlineAccessView = observer(({ store }: { store: SetUpOnlineAccessStore }) => {
	const [formProps, formKey] = useSafeSpread(store.form.reactProps);
	const { magicModal } = useGlobalContext();
	const {
		form: {
			fields,
		},
	} = store;

	return (
		<form key={formKey} {...formProps}>
			<Field field={fields.username} />
			<Field field={fields.zipCode} />
			<CheckboxField field={fields.optIn} />
			<div className="tw-space-x-8">
				<Button data-qa="create-account-modal-submit-button">
					Submit
				</Button>
				<TextButton
					type="button"
					onClick={magicModal.closeModal}
				>
					Cancel
				</TextButton>
			</div>
		</form>
	);
});
