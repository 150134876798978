import React from 'react';
import type { GetServerSidePropsContext } from 'next';
import axios, { type AxiosResponse, type AxiosError } from 'axios';
import {
	model, Model, prop, idProp, modelFlow, _await, _async, modelAction, objectMap,
} from 'mobx-keystone';
import { observable } from 'mobx';

import type { AppConfigPageProps } from '~/global/app-config/AppConfig.types';
import { type Context, type Header, getIpHeaders } from '~/global/app-config/ipHeaders';
import { MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { bootstrapLinks } from '~/global/bootstrapLinks.constants';
import { modelNamespace } from '~/util/modelNamespace';
import { OpportunitiesToolbarModel } from '~/engage/opportunities/Models/OpportunitiesToolbar.model';
import { OpportunitiesModel } from '~/engage/opportunities/Models/Opportunities.model';
import { OpportunityStore } from '~/engage/opportunities/Stores/Opportunity.store';
import { OpportunityNotesStore } from '~/engage/opportunities/Stores/OpportunityNotes.store';
import { isOnServer } from '~/global/global.constants';
import { setCookieHeader } from '~/util/setCookieHeader';
import { OpportunityModel } from '~/engage/opportunities/Models/Opportunity.model';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { PromiseErrorMessaging } from '~/util/messaging/promise-error/PromiseErrorMessaging';
import { ReassignOpportunityModal } from '~/engage/opportunities/Components/ReassignOpportunityModal';
import { MyOpportunityTrackingStore } from '~/tracking/my-opportunity-events/Stores/MyOpportunity.tracking.store';
import { TriggeredEmailModel } from '~/engage/opportunities/Models/TriggeredEmail.model';

type MyOpportunityDto = {
	dateCreated?: string
	employeeName?: string
	isMyOpportunity: boolean
	_links?: {
		createNewOpportunity?: {
			href: string
		}
		reassignOpportunity?: {
			href: string
		}
		removeOpportunity?: {
			href: string
		}
		viewOpportunities?: {
			href: string
		}
	}
};

export const opportunityCategories = {
	FOLLOW_UP_REQUIRED: 'Follow-up Required',
	PROJECTS_IN_PROGRESS: 'Projects in Progress',
	PENDING_CUSTOMER_DECISION: 'Pending Customer Decision',
	NEW_OPPORTUNITY: 'New Opportunities',
} as const;

export type OpportunityCategory = typeof opportunityCategories;
export type OpportunityCategoryKey = keyof OpportunityCategory;
export type OpportunityCategoryValue = OpportunityCategory[OpportunityCategoryKey];

export const triggeredEmailTypes = {
	GENERAL_CHECK_IN: 'General Check-in',
	SAMPLE_FOLLOW_UP: 'Sample Follow-up',
	DESIGN_PRESENTATION_FOLLOW_UP: 'Design Presentation Follow-up',
	FINAL_FOLLOW_UP: 'Final Follow-up',
} as const;
export type TriggeredEmailType = typeof triggeredEmailTypes;
export type TriggeredEmailTypeKey = keyof TriggeredEmailType;
export type TriggeredEmailTypeValue = TriggeredEmailType[TriggeredEmailTypeKey];
export type TriggeredEmail = {
	triggeredEmailType: TriggeredEmailTypeKey,
	dateSent?: string
	email?: string
	_links?: {
		sendTriggeredEmail: {
			href: string
		}
	}
}

type OpportunitiesPageDto = {
	categories: {
		[key in OpportunityCategoryKey]: {
			id: string
			customerName: string
			category: key
			customDateUpdated: string
			dateCreated: string
			moveToCategories: OpportunityCategoryKey[]
			notes?: string
			_links: {
				removeOpportunity?: {
					href?: string
				}
				moveOpportunityToCategory?: {
					href?: string
				}
				opportunityNotes?: {
					href?: string
				}
				proxy: {
					href: string
				}
				pinOpportunity?: {
					href?: string
				}
				unpinOpportunity?: {
					href?: string
				}
			}
			triggeredEmails?: TriggeredEmail[]
		}[]
	}
};
type GlobalDynamicStore = {
	fetchData: Function
	model: {
		myOpportunity: MyOpportunityDto
	}
};
type MagicOverlay = {
	startLoading: Function
	stopLoading: Function
};

@model(`${modelNamespace.OPPORTUNITIES}/OpportunitiesStore`)
export class OpportunitiesStore extends Model({
	id: idProp,
	toolbarModel: prop<OpportunitiesToolbarModel | undefined>().withSetter(),
	pageModel: prop<OpportunitiesModel | undefined>().withSetter(),
}) {
	globalDynamic: unknown;

	magicModal: unknown;

	magicOverlay: unknown;

	featureTogglesModel: any;

	@observable.ref
	reassignOpportunityPromiseError?: PromiseError;

	@modelFlow
	createNewOpportunity = _async(function* (this: OpportunitiesStore, link: string) {
		if (!link) {
			throw new Error('No `link` found.');
		}
		if (!this.hasMagicOverlay(this.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		try {
			const promise = axios.post(link);

			this.magicOverlay.startLoading();
			const response = yield* _await(promise);
			yield* _await(this.fetchGlobalDynamicData());

			const {
				analytics,
			} = response.data;

			MyOpportunityTrackingStore.trackAddOpp(analytics);
		} catch (error: unknown) {
			if (!axios.isAxiosError(error)) {
				console.error(error);
				return;
			}
			this.onError(error);
		} finally {
			this.magicOverlay.stopLoading();
		}
	});

	@modelFlow
	fetchGlobalDynamicData = _async(function* (this: OpportunitiesStore) {
		if (!this.hasGlobalDynamic(this.globalDynamic)) {
			return;
		}
		try {
			yield* _await(this.globalDynamic.fetchData());
			this.updateToolbar(this.globalDynamic.model.myOpportunity);
		} catch (error: unknown) {
			console.error(error);
		}
	});

	@modelFlow
	fetchPageData = _async(function* (
		this: OpportunitiesStore,
		pageProps?: AppConfigPageProps,
		ctx?: GetServerSidePropsContext,
	) {
		this.setAxiosHeaders(ctx);
		try {
			const headers = setCookieHeader(pageProps);
			const promise = axios.request({
				url: bootstrapLinks.OPPORTUNITIES.entry,
				method: 'get',
				...headers && { headers },
			});
			const response: AxiosResponse<OpportunitiesPageDto> = yield* _await(promise);

			this.updatePage(response.data);
		} catch (error: unknown) {
			console.error(error);
			if (isOnServer) {
				throw error;
			}
		}
	});

	onReassignOpportunityFailure(error: unknown) {
		if (!axios.isAxiosError(error)) {
			console.error(error);
			return;
		}
		this.reassignOpportunityPromiseError = new PromiseError(error);
	}

	@modelFlow
	onReassignOpportunitySuccess = _async(function* (this: OpportunitiesStore) {
		if (!this.hasMagicOverlay(this.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		if (!this.hasMagicModal(this.magicModal)) {
			throw new Error('No magicModal found.');
		}
		if (!this.hasGlobalDynamic(this.globalDynamic)) {
			throw new Error('No globalDynamic found.');
		}
		try {
			yield* _await(this.fetchGlobalDynamicData());
			this.magicModal.closeModal();
			this.magicOverlay.stopLoading();
		} catch (error: unknown) {
			this.onReassignOpportunityFailure(error);
		}
	});

	openReassignOpportunityModal() {
		if (!this.hasMagicModal(this.magicModal)) {
			throw new Error('No magicModal found.');
		}
		if (!this.toolbarModel) {
			throw new Error('No toolbarModel found.');
		}
		this.reassignOpportunityPromiseError = undefined;
		this.magicModal.openModal({
			title: 'Follow This Opportunity?',
			maxWidth: '500px',
			content: {
				children: <ReassignOpportunityModal store={this} />,
			},
		});
	}

	@modelFlow
	reassignOpportunity = _async(function* (this: OpportunitiesStore) {
		if (!this.hasMagicOverlay(this.magicOverlay)) {
			throw new Error('No magicOverlay found.');
		}
		if (!this.toolbarModel) {
			throw new Error('No toolbarModel found.');
		}
		if (!this.toolbarModel.reassignOpportunityLink) {
			throw new Error('No reassignOpportunityLink found.');
		}
		const timeoutId = setTimeout(this.magicOverlay.startLoading, 2000);
		try {
			const promise = axios.post(this.toolbarModel.reassignOpportunityLink);

			this.reassignOpportunityPromiseError = undefined;
			const response = yield* _await(promise);
			this.onReassignOpportunitySuccess();

			const {
				data: {
					beforeReassignment: {
						analytics: beforeAnalytics = {},
					} = {},
					afterReassignment: {
						analytics: afterAnalytics = {},
					} = {},
				}
			} = response;

			console.info(response.data);
			MyOpportunityTrackingStore.trackReassignOpp(beforeAnalytics, afterAnalytics);
		} catch (error: unknown) {
			this.onReassignOpportunityFailure(error);
			this.magicOverlay.stopLoading();
		} finally {
			clearTimeout(timeoutId);
		}
	});

	setAxiosHeader(headerName: string, header?: Header) {
		const showLog = isOnServer && process.env.NODE_ENV === 'production';

		if (header) {
			if (showLog) {
				console.log(`Forwarding request header: ${headerName}: ${header}`);
			}
			axios.defaults.headers.common[headerName] = header;
			return;
		}
		if (showLog) {
			console.log(`No request header found: ${headerName}`);
		}
		delete axios.defaults.headers.common[headerName];
	}

	setAxiosHeaders(ctx?: Context) {
		if (!isOnServer || !ctx) {
			return;
		}
		const {
			trueClientIpHeader,
			xForwardedForHeader,
			xBypassRatelimitingHeader,
		} = getIpHeaders(ctx);

		this.setAxiosHeader('True-Client-IP', trueClientIpHeader);
		this.setAxiosHeader('X-Forwarded-For', xForwardedForHeader);
		this.setAxiosHeader('X-Bypass-Ratelimiting', xBypassRatelimitingHeader);

		if (isOnServer && ctx) {
			const handlerLog = ctx.req ? `Handling request for original URL ${ctx.req.originalUrl}\n` : '';

			console.log(handlerLog);
		}
	}

	hasGlobalDynamic(value: unknown): value is GlobalDynamicStore {
		return (
			typeof value === 'object'
			&& value !== null
			&& 'fetchData' in value
			&& typeof value.fetchData === 'function'
			&& 'model' in value
			&& typeof value.model === 'object'
			&& value.model !== null
			&& 'myOpportunity' in value.model
		);
	}

	hasMagicModal(value: unknown): value is MagicModalStore {
		return value instanceof MagicModalStore;
	}

	hasMagicOverlay(value: unknown): value is MagicOverlay {
		return (
			typeof value === 'object'
			&& value !== null
			&& 'startLoading' in value
			&& typeof value.startLoading === 'function'
			&& 'stopLoading' in value
			&& typeof value.stopLoading === 'function'
		);
	}

	onError(error: AxiosError) {
		if (!this.hasMagicModal(this.magicModal)) {
			console.log('nope');
			return;
		}
		const promiseError = new PromiseError(error);
		this.magicModal.closeModal();
		this.magicModal.openModal({
			title: promiseError.errorKey === 'blockedCustomerError' ? 'Alert' : 'Error',
			maxWidth: '500px',
			content: {
				children: <PromiseErrorMessaging errorMessage={promiseError.errorMessageObj} inline />
			}
		});
	}

	@modelAction
	updatePage(data: OpportunitiesPageDto) {
		const {
			categories: categoriesData,
		} = data;
		const categoryMap: { [key in OpportunityCategoryKey]: OpportunityStore[] } = {
			FOLLOW_UP_REQUIRED: [],
			NEW_OPPORTUNITY: [],
			PENDING_CUSTOMER_DECISION: [],
			PROJECTS_IN_PROGRESS: [],
		};

		Object.entries(categoriesData).forEach(([key, categories]) => {
			categoryMap[key as OpportunityCategoryKey] = categories.map((item) => {
				const opportunityModel = new OpportunityModel({
					category: item.category,
					customerName: item.customerName,
					dateCreated: item.dateCreated,
					customDateUpdated: item.customDateUpdated,
					moveToCategories: item.moveToCategories,
					triggeredEmails: item.triggeredEmails?.map((triggeredEmail) => {
						return new TriggeredEmailModel({
							triggeredEmailType: triggeredEmail.triggeredEmailType,
							dateSent: triggeredEmail.dateSent,
							email: triggeredEmail.email,
							sendTriggeredEmailLink: triggeredEmail._links?.sendTriggeredEmail.href
						});
					}),
				});
				const opportunityStore = new OpportunityStore({
					id: item.id,
					model: opportunityModel,
					moveOpportunityToCategoryLink: item._links?.moveOpportunityToCategory?.href,
					notes: new OpportunityNotesStore({
						notes: item.notes,
						opportunityNotesLink: item._links?.opportunityNotes?.href,
					}),
					pinOpportunityLink: item._links?.pinOpportunity?.href,
					proxyLink: item._links?.proxy.href,
					removeOpportunityLink: item._links?.removeOpportunity?.href,
					unpinOpportunityLink: item._links?.unpinOpportunity?.href,
				});
				return opportunityStore;
			});
		});
		this.setPageModel(new OpportunitiesModel({
			categories: objectMap(Object.entries(categoryMap)),
		}));
	}

	@modelAction
	updateToolbar(incomingData?: MyOpportunityDto) {
		let data = incomingData;

		if (!data && this.hasGlobalDynamic(this.globalDynamic) && this.globalDynamic.model.myOpportunity) {
			data = this.globalDynamic.model.myOpportunity;
		}
		// Return early instead of erroring since we could be a new guest.
		if (!data) {
			this.setToolbarModel(new OpportunitiesToolbarModel({
				isMyOpportunity: false,
			}));
			return;
		}
		const {
			employeeName,
			dateCreated,
			isMyOpportunity,
			_links: {
				createNewOpportunity: {
					href: createNewOpportunityLink = undefined,
				} = {},
				reassignOpportunity: {
					href: reassignOpportunityLink = undefined,
				} = {},
				removeOpportunity: {
					href: removeOpportunityLink = undefined,
				} = {},
				viewOpportunities: {
					href: viewOpportunitiesLink = undefined,
				} = {},
			} = {},
		} = data;

		this.setToolbarModel(new OpportunitiesToolbarModel({
			createNewOpportunityLink,
			employeeName,
			dateCreated,
			isMyOpportunity,
			reassignOpportunityLink,
			removeOpportunityLink,
			viewOpportunitiesLink,
		}));
	}
}
