import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';

export const AdvancedResidentialLookup = observer((props) => {
	const {
		store: {
			isFormValid,
			form: {
				reactProps,
				fields: {
					firstName: firstNameField,
					lastName: lastNameField,
					street: streetField,
					city: cityField,
					zipCode: zipCodeField,
				}
			}
		}
	} = props;

	const {
		key,
		...formReactProps
	} = reactProps;

	return (
		<form key={key} {...formReactProps}>
			<div>
				<p className="wildcard-text">
					Minimum two fields, two characters each.
					Wildcard searches allowed; use *
				</p>
			</div>
			<div className="form-layout">
				<Field field={firstNameField} />
				<Field field={lastNameField} />
				<Field field={streetField} />
				<Field field={cityField} />
				<Field field={zipCodeField} />
			</div>
			<input
				type="submit"
				className="ButtonTertiary"
				value="Search"
				disabled={!isFormValid}
				data-qa="customer-search-button"
			/>
		</form>

	);
});
