import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';
import { useSafeSpread } from '~/hooks/useSafeSpread';

export const AdvancedBusinessLookup = observer((props) => {
	const {
		store: {
			isFormValid,
			form: {
				reactProps,
				fields: {
					firstName: firstNameField,
					lastName: lastNameField,
					businessName: businessNameField,
					street: streetField,
					city: cityField,
					zipCode: zipCodeField,
				}
			}
		}
	} = props;
	const [formProps, formKey] = useSafeSpread(reactProps);

	return (
		<form key={formKey} {...formProps}>
			<div>
				<p className="wildcard-text">
					Minimum two fields, two characters each.
					Wildcard searches allowed; use *
				</p>
			</div>
			<div className="form-layout">
				<Field field={firstNameField} />
				<Field field={lastNameField} />
				<Field field={businessNameField} />
				<Field field={streetField} />
				<Field field={cityField} />
				<Field field={zipCodeField} />
			</div>
			<input
				type="submit"
				className="ButtonTertiary"
				value="Search"
				disabled={!isFormValid}
				data-qa="customer-search-button"
			/>
		</form>

	);
});
