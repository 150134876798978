/**
 * @deprecated MARKETING_MMS_OPTIN_ENGAGE (entire file)
 */

export const LegacySuccess = (props: { cancelHandler: () => void }) => {
	return (
		<div>
			<p>An email has been sent with information on how to complete account setup and see their Cart and Favorites.</p>
			<div className="form-actions">
				<button className="Button" data-qa="create-account-success-modal-close-button" onClick={(event) => {
					event.preventDefault();
					props.cancelHandler();
				}}>Close</button>
			</div>
		</div>
	);
};
