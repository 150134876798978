import React from 'react';
import { observer } from 'mobx-react';

import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

export const RecentCustomers = observer(({ store = {} }) => {
	const {
		isLoading,
		customers = [],
		seeMoreRecentCustomers,
		proxyRecentCustomer,
		recentMoreCustomersLink,
	} = store;
	const entryStyle = {
		color: 'inherit',
		fontSize: 'inherit',
		wordWrap: 'break-word',
		wordBreak: 'break-all',
	};

	if (isLoading) {
		return (
			<div>
				<LoadingSpinner minHeight={168} isLoading={isLoading} />
			</div>
		);
	}

	return (
		<div>
			<ol>
				{
					customers.map((item, i) => {
						const activityDate = new Intl.DateTimeFormat('en-US', {
							month: '2-digit',
							day: '2-digit'
						}).format(new Date(item.lastActivityDate));

						return (
							<li key={`recent-customers-list-item-${i}`} className="tw-mb-2.5">
								<button
									className="tw-text-left button-as-anchor"
									data-track="Recent Customer Selected"
									data-tr-link-event-type={LinkEventTypes.INTERNAL}
									onClick={(event) => {
										const link = item.proxyLink;

										event.preventDefault();
										if (link) {
											proxyRecentCustomer(link);
										}
									}}
								>
									<span style={entryStyle} data-qa="recent-customers-entry">{item.name} </span>
									<span style={entryStyle} className="tw-text-3xs" data-qa="recent-customers-entry">
										{activityDate}
									</span>
								</button>
							</li>
						);
					})
				}
				{
					recentMoreCustomersLink &&
					<li key="see-more-recent-customers" className="tw-mb-2.5">
						<button
							type="button"
							className="tw-text-left button-as-anchor"
							onClick={seeMoreRecentCustomers}
							data-qa="see-more-recent-customers-button"
						>
							<span style={entryStyle} data-qa="recent-customers-entry">
								See more recent customers »
							</span>
						</button>
					</li>
				}
			</ol>
		</div>
	);
});
