import { apiUrl } from '~/global/global.constants';

export const rnbFetch = async (url: RequestInfo | URL, headers?: any, options?: RequestInit): Promise<Response> => {
	const apiRequestUrl = `${apiUrl}/${url}`.replace('//api', '/api');

	console.info('rnbFetch: ', apiRequestUrl);
	return fetch(apiRequestUrl, {
		method: 'get',
		credentials: 'include',
		...(headers && { headers }),
		...(options && { ...options }),
	});
};
